<template>
  <page-loading v-if="state.pageLoading"></page-loading>
  <div v-if="!state.pageLoading" class="page-container-new">
    <page-header title="콘텐츠">
      <template #headerRightSide>
        <button-basic
          text="콘텐츠 생성하기"
          icon-position="front"
          padding="8px 16px"
          @action="actions.openCreateContentModal()"
        >
          <template #icon>
            <plus-icon fill-color="#ffffff"></plus-icon>
          </template>
        </button-basic>
      </template>
    </page-header>

    <div class="page-wrapper">
      <div class="tab-section">
        <div class="tab-btn-wrapper">
          <button-tab
            v-for="(item, index) in state.types"
            :key="`type-${index}`"
            :text="`${item.label + ' ' + item.count}`"
            :active="state.filters.type == item.type"
            @click="actions.filterContents({ type: item.type })"
          ></button-tab>
        </div>
      </div>
      <div class="search-area">
        <input-search-new
          v-model="state.filters.q"
          shape="round"
          placeholder="콘텐츠를 검색해주세요."
          search-type="pressEnter"
          @search="actions.filterContents({ q: state.filters.q })"
        ></input-search-new>
      </div>

      <div class="column-head">
        <span class="col1 sub-text-s3 text-gray-second">SNS 콘텐츠</span>
        <span class="col2 sub-text-s3 text-gray-second">상태</span>
        <span class="col3 sub-text-s3 text-gray-second">등록일</span>
      </div>

      <div v-show="state.contentsLoading" class="page-loading-area">
        <page-loading></page-loading>
      </div>

      <div v-show="!state.contentsLoading" class="content-wrapper">
        <ul v-if="state.contents.length > 0" class="content-list">
          <li
            v-for="content in state.contents"
            :key="`channel-content-${content.resourceId}`"
            class="content-item"
            @click="actions.goToContentEdit(content.resourceId)"
          >
            <channel-content-item :content="content"></channel-content-item>
          </li>
        </ul>
        <!--	session empty  -->
        <div v-if="state.contents.length === 0" class="session-empty-card">
          <div class="directory-img">
            <img src="/assets/images/channels/contents-empty.png" width="72" />
          </div>
          <p class="sub-text-s2 text-gray-second">
            생성된 콘텐츠가 없습니다.<br />
            콘텐츠 생성 버튼을 통해 라이브를 추가해보세요!
          </p>
          <button class="creat-btn" @click="actions.openCreateContentModal()">
            <span class="sub-title-s2 text-default">콘텐츠 추가하기</span>
            <arrow-icon class="arrow-icon"></arrow-icon>
          </button>
        </div>
      </div>

      <div
        v-if="state.contentsMeta.pagination.lastPage > 1"
        class="pagination-wrapper"
      >
        <pagination
          :pagination="state.contentsMeta.pagination"
          @getPageData="(page) => actions.filterContents({ page: page })"
        ></pagination>
      </div>
    </div>
  </div>
  <contents-setting-info-modal
    v-if="state.showCreateContentModal"
    warning-title="콘텐츠 추가하기"
    confirm-text="추가"
    cancel-text="취소"
    @confirm="
      (payload) => {
        actions.createContent(payload);
      }
    "
    @hideModal="actions.closeCreateContentModal()"
  ></contents-setting-info-modal>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  reactive,
  watch,
} from "vue";
import { useStore } from "vuex";
import { dateFormat } from "@/helper/date";
import Helper from "@/helper";
import Pagination from "../../../components/console/pagination/Pagination";
import PageLoading from "../../../components/console/loadings/PageLoading";
import { useRoute } from "vue-router";
import PageHeader from "../../../components/console/headers/PageHeader";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import PlusIcon from "../../../components/console/icons/PlusIcon";
import InputSearchNew from "@/components/console/inputs/InputSearchNew.vue";
import ButtonTab from "../../../components/console/buttons/ButtonTab";
import ChannelContentItem from "./ChannelContentItem/ChannelContentItem";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ContentsSettingInfoModal from "@/pages/console/ContentsList/ContentsSettingInfoModal/ContentsSettingInfoModal.vue";
import { useMoveRoute } from "@/helper/useMoveRoute";

export default {
  name: "ContentsList",
  components: {
    ContentsSettingInfoModal,
    ArrowIcon,
    PlusIcon,
    ButtonBasic,
    PageHeader,
    PageLoading,
    Pagination,
    ButtonTab,
    ChannelContentItem,
    InputSearchNew,
  },
  setup() {
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const { moveRouteQueryParameter, moveRoute } = useMoveRoute();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      contents: computed(() => {
        return store.getters["contents/contents"];
      }),
      contentsMeta: computed(() => {
        return store.getters["contents/contentsMeta"];
      }),
      types: computed(() => {
        return store.getters["contents/types"];
      }),
      filters: {
        q: "",
        type: null,
        page: 1,
      },
      pageLoading: false,
      contentsLoading: false,
      emptyTitle: computed(() => {
        if (state.filters.q) {
          return `"${state.filters.q}"에 대한 검색결과가 없습니다.`;
        }

        if (state.filters.type === proxy.$const.contentType.all.type) {
          if (state.user.userProfile.displayName) {
            return `${state.user.userProfile.displayName}님의 첫번째 콘텐츠를 등록해 보세요`;
          } else {
            return "첫번째 콘텐츠를 등록해 보세요!";
          }
        } else {
          const contentType = Object.values(proxy.$const.contentType).find(
            (item) => {
              const filterType = state.filters.type ? state.filters.type : null;
              return filterType === item.type;
            }
          );

          return `${contentType.label} 콘텐츠가 없습니다.`;
        }
      }),
      showCreateContentModal: false,
    });

    onBeforeMount(() => {
      state.pageLoading = true;
      getContents().then(() => {
        state.pageLoading = false;
      });
    });

    watch(
      () => route.query,
      (query) => {
        state.contentsLoading = true;
        getContents().then(() => {
          state.contentsLoading = false;
        });
      }
    );

    const getContents = () => {
      const query = route.query;
      state.filters = { ...state.filters, ...query };
      return store.dispatch("contents/getContents", state.filters);
    };

    const resetFiltersPage = (filterData) => {
      const isTypeKey = Object.prototype.hasOwnProperty.call(
        filterData,
        "type"
      );
      if (isTypeKey) {
        state.filters = { ...state.filters, page: 1 };
      }
    };

    const actions = {
      goToContentEdit: (contentResourceId) => {
        moveRoute({ path: `/console/contents/${contentResourceId}/edit` });
      },
      filterContents: (params) => {
        resetFiltersPage(params);
        state.filters = { ...state.filters, ...params };
        moveRouteQueryParameter({
          path: "/console/contents",
          query: state.filters,
        });
      },
      createContent: (payload) => {
        store.dispatch("contents/postContent", payload).then(() => {
          const resourceId = store.getters["contents/content"].resourceId;

          moveRoute({
            routeName: "console.contents.edit",
            params: {
              contentResourceId: resourceId,
            },
          });
        });
      },
      openCreateContentModal: () => {
        state.showCreateContentModal = true;
      },
      closeCreateContentModal: () => {
        state.showCreateContentModal = false;
      },
    };
    return { state, actions, dateFormat, Helper, proxy };
  },
};
</script>

<style src="./style.css" scoped></style>
